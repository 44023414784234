import { Injectable, Injector } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { OktaAuthService } from '@okta/okta-angular';
import { AuthRequiredFunction } from '@okta/okta-auth-js';
import { ApplicationInsightsService } from '../services/ApplicationInsightsService';

@Injectable()
export class EobOktaAuthGuard implements CanActivate {
  constructor(private oktaAuth: OktaAuthService,
    private injector: Injector,
    private applicationInsightsService: ApplicationInsightsService,
    private router: Router) { }

  /**
   * Gateway for protected route. Returns true if there is a valid accessToken,
   * otherwise it will cache the route and start the login flow.
   * @param route
   * @param state
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.applicationInsightsService.logInfo('EobOktaAuthGuard considering route activation.');

    if (await this.oktaAuth.isAuthenticated()) {
      this.applicationInsightsService.logInfo('EobOktaAuthGuard determined user is oktaAuthenticated and activating route.');

      return true;
    }

    /**
     * Get the operation to perform on failed authentication from
     * either the global config or route data injection.
     */
    const onAuthRequired: AuthRequiredFunction = route.data['onAuthRequired'] || this.oktaAuth.getOktaConfig().onAuthRequired;

    /**
     * Store the current path
     */
    this.oktaAuth.setFromUri(state.url);

    if (onAuthRequired) {
      this.applicationInsightsService.logVerbose('EobOktaAuthGuard detected custom onAuthRequired handler and is executing custom handler.');

      onAuthRequired(this.oktaAuth, this.injector);
    } else {
      this.applicationInsightsService.logVerbose('EobOktaAuthGuard is calling oktaAuth.loginRedirect().');

      this.router.navigate(['']);
    }

    this.applicationInsightsService.logVerbose('EobOktaAuthGuard has determined it should not activate.');

    return false;
  }
}
