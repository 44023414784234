import { IExceptionTelemetry } from '@microsoft/applicationinsights-common';

export class ExceptionTelemetryEvent implements IExceptionTelemetry {

  constructor(error: Error, properties: { [key: string]: any; }) {
    this.error = error;
    this.properties = properties;
  }

  id?: string;
  error: Error;
  severityLevel?: number;
  properties?: { [key: string]: any; };
  measurements?: { [key: string]: number; };
}
