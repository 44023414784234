<div class="flex-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>User Preferences</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-slide-toggle class="example-margin" style="padding: 0 16px;"
                        [(ngModel)]="receiveEmail" (change)="updateUser()">
        Send me a daily email summary of all {{itemDescription}}  I oversee.
      </mat-slide-toggle>
    </mat-card-content>
  </mat-card>
</div>
