import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/model/User';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmRemoveDialogComponent } from '../select-user/confirm-remove-dialog.component';
import { BrandingService } from '../../services/branding.service';

@Component({
    selector: 'app-remove-user',
    templateUrl: 'remove-user.component.html'
})
export class RemoveUserComponent {
    @Input() selectedUser: User;
    @Output() userRemoved: EventEmitter<User> = new EventEmitter();

  constructor(public dialog: MatDialog,
    private toastr: ToastrService,
    public brandingService: BrandingService) {
  }
    confirmRemoveUser() {
      const confirmationDialog = this.dialog.open(ConfirmRemoveDialogComponent, {
        data: this.selectedUser
      });

      confirmationDialog.afterClosed().subscribe((result) => {
        if (this.selectedUser === undefined) {
          this.toastr.error('Removal confirmed but user not identified.', 'System Error');
        } else {
          if (result) {
            this.userRemoved.emit(this.selectedUser);
          }
        }
      });
  }


}
