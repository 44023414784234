<div class="edit-user-container flex wrap" [hidden]="isClaimantUser">
    <app-search-vendors [selectedUser]="selectedUser" (searching)="searchingVendors($event)"
        (vendorsFound)="updateVendors($event, true)" (searchCleared)="resetVendors()"></app-search-vendors>
    <div class="flex container-vertical vertical-align-right vendor-list" [hidden]="loading || dataSource.data.length === 0">
        <div class="flex space-between align-items-center fill-width">
            <div class="grey-font table-header">{{isSearchResults ? "Vendor Search Results" : "Associated Vendors"}}</div>
            <mat-form-field class="eob-filter">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter()" placeholder="Filter by Firm Name, Tax Id, Vendor Id" [(ngModel)]="filterValue">
                <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="mat-elevation-z8 fill-width">
            <table mat-table [dataSource]="dataSource" class="eob-list" matSort>
                <ng-container matColumnDef="choose">
                    <th mat-header-cell *matHeaderCellDef> Associated </th>
                    <td mat-cell *matCellDef="let vendorAssociation"> <button mat-raised-button class="main-button" [color]="vendorAssociation.associatedToUser ? 'warn' : 'accent'" [disabled]="hasNoUserSelected"
                            (click)="selectVendor($event, vendorAssociation)">{{vendorAssociation.associatedToUser ? "Disassociate" : "Associate"}}</button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="vendorId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendor Id </th>
                    <td mat-cell *matCellDef="let vendorAssociation"> {{vendorAssociation.vendor.vendorId}} </td>
                </ng-container>
                <ng-container matColumnDef="taxId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tax Id </th>
                    <td mat-cell *matCellDef="let vendorAssociation"> {{vendorAssociation.vendor.taxId}} </td>
                </ng-container>
                <ng-container matColumnDef="firmName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Firm Name </th>
                    <td mat-cell *matCellDef="let vendorAssociation"> {{vendorAssociation.vendor.firmName}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
    <app-loading [hidden]="!loading"></app-loading>
    <mat-card [hidden]="loading || dataSource.data.length !== 0">
        <mat-card-title>No vendors associated to this user.</mat-card-title>
        <mat-card-content>If you would like to associate vendors to this user: use the search box to the left to find vendors, then click associate.</mat-card-content>
    </mat-card>
</div>
