import { Component, Pipe, PipeTransform, Output, EventEmitter } from '@angular/core';
import { EobApiService } from '../../services/EobApiService';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { saveAs } from 'file-saver';
import { ApplicationInsightsService } from '../../services/ApplicationInsightsService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-vendor-report',
  templateUrl: './user-vendor-report.component.html'
})
export class UserVendorReportComponent {
  loading: boolean;
  constructor(private apiService: EobApiService,
    private apiErrorService: EobApiErrorService,
    private applicationInsightsService: ApplicationInsightsService,
    private toastrService: ToastrService) {
  }

  downloadReport() {
    this.loading = true;
    this.apiService.userVendorAssociationReport().subscribe(
      response => {
        try {
          saveAs(response.body, 'UserVendorReport.csv', { type: 'text/csv' });
        } catch (e) {
          this.applicationInsightsService.logError(e);

          this.toastrService.error('Unable to save User / Vendor Report file.');
        }
      },
      error => {
        this.apiErrorService.handleApiErrorResponse(error, 'Getting User Vendor Report');
      },
      () => {
        this.loading = false;
      }
    );
  }
}
