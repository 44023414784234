import { Component, ViewChild, Input, ViewChildren, QueryList, SimpleChanges, OnChanges } from '@angular/core';
import { Vendor } from '../../model/vendor';
import { User } from '../../model/User';
import { EobApiService } from '../../services/EobApiService';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { UserVendorListComponent } from '../user-vendor-list/user-vendor-list.component';
import { SelectUserComponent } from '../select-user/select-user.component';
import { ManageAdminComponent } from '../manage-admin/manage-admin.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../services/ApplicationInsightsService';
import { isNullOrUndefined } from 'util';
import { UserService } from '../../services/UserService';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-manage-user',
  templateUrl: 'manage-user.component.html',
})
export class ManageUserComponent {
  selectedUser: User;
  showCreateUser = false;
  loading = false;

  @ViewChild('userVendorList', { static: false }) userVendorList: UserVendorListComponent;
  @ViewChild('userList', { static: false }) userList: SelectUserComponent;
//  @ViewChildren('manageAdmin') adminComponent: QueryList<ManageAdminComponent>;
  manageAdmin: ManageAdminComponent;



  constructor(public apiService: EobApiService,
    private apiErrorService: EobApiErrorService,
    private applicationInsightsService: ApplicationInsightsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private brandingService: BrandingService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(async params => {
      const oktaUserId = params.get('oktaUserId');
      this.getUser(oktaUserId);
    });
  }

  getUser(oktaUserId: string): void {
    if (oktaUserId != null && oktaUserId !== undefined) {
      this.apiService.getUser(oktaUserId).subscribe(
        (user) => this.updateUser(user),
        (error) => this.apiErrorService.handleApiErrorResponse(error, 'Invalid User'));
    }
  }

  updateUser(user: User) {
    this.selectedUser = user;
    this.apiService.getAdminRights(user.oktaUserId).subscribe(isAdmin => { this.selectedUser.isAdmin = isAdmin; });
    this.apiService.getClaimantRights(user.oktaUserId).subscribe(isClaimant => { this.selectedUser.isClaimantUser = isClaimant; });
    const userNoLongerSelected = user == null;
    if (userNoLongerSelected) {
      this.userVendorList.updateVendors([], false);
      this.manageAdmin = undefined;
    } else {
      this.loading = true;

      if (this.manageAdmin !== undefined) {
        this.updateAdminStatus();
        this.updateClaimantStatus();
      }

      this.getUserVendorList(user.oktaUserId);
    }
  }

  updateAdminStatus() {
    this.loading = true;

    this.apiService.getAdminRights(this.selectedUser.oktaUserId).subscribe(isAdmin => {
      this.selectedUser.isAdmin = isAdmin;
    },
      error => {
        this.selectedUser.isAdmin = false;
        if (error.status === 502) {
          this.toastrService.warning('Unable to determine admin rights.<br/><br/>This user does not have an Okta account.', 'Could not get admin rights', { enableHtml: true });
        }
        else {
          this.apiErrorService.handleApiErrorResponse(error, 'Could not get admin rights');
        }
      },
      () => {
        this.loading = false;
      });
  }

  updateClaimantStatus() {
    this.loading = true;

    this.apiService.getClaimantRights(this.selectedUser.oktaUserId).subscribe(isClaimantUser => {
      this.selectedUser.isClaimantUser = isClaimantUser;
    },
      error => {
        this.selectedUser.isClaimantUser = false;
        if (error.status === 502) {
          this.toastrService.warning('Unable to determine claimant rights.<br/><br/>This user does not have an Okta account.', 'Could not get claimant rights', { enableHtml: true });
        }
        else {
          this.apiErrorService.handleApiErrorResponse(error, 'Could not get claimant rights');
        }
      },
      () => {
        this.loading = false;
      });
  }
  

  selectUser(user: User) {
    if (isNullOrUndefined(user)) {
      this.selectedUser = undefined;

      return;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { oktaUserId: user.oktaUserId },
        queryParamsHandling: 'merge'
      });
  }

  respondToVendorSearchCleared() {
    this.getUserVendorList(this.selectedUser.oktaUserId);
  }

  handleCreateUser(createUser: boolean) {
    this.showCreateUser = createUser;
  }

  removeUser(user: User) {
    this.userList.removeUser(user);
  }

  userCreated(userCreated) {
    this.showCreateUser = false;
  }

  viewEobSummariesAs(user: User) {
    this.router.navigate([this.brandingService.subdomain], { queryParams: { oktaUserId: user.oktaUserId || 'smellyoulater' } });
  }

  getUserVendorList(oktaUserId: string) {
    this.apiService.getUserVendorList(oktaUserId).subscribe((vendorAssociations) => {
        try {
          this.userVendorList.updateVendors(vendorAssociations, false);
        } catch (e) {
          this.applicationInsightsService.logError(e);

          this.loading = false;
        }
      },
      errorResponse => {
        this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid User');
      },
      () => {
        this.loading = false;
      });
  }

  onUserAdminRightsChange($event: boolean) {
    this.selectedUser.isAdmin = $event;
  }

  onUserClaimantRightsChange($event: boolean) {
    this.selectedUser.isClaimantUser = $event;
  }
}
