import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationErrorService {
  private authenticationHasErrored = new Subject<boolean>();

  getStatus(): Observable<boolean> {
    return this.authenticationHasErrored.asObservable();
  }

  updateStatus(flag: boolean) {
    this.authenticationHasErrored.next(flag);
  }
}
