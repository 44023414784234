export class UserEmailPreference {
  oktaUserId: string;
  receiveEmail: boolean;

  setOktaUserId(oktaUserId: string): UserEmailPreference {
    this.oktaUserId = oktaUserId;

    return this;
  }

  setReceiveEmail(receiveEmail: boolean): UserEmailPreference {
    this.receiveEmail = receiveEmail;

    return this;
  }
}
