import { ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-common';

export class TraceTelemetryEvent implements ITraceTelemetry {
  constructor(severityLevel: SeverityLevel, message: string, properties: { [key: string]: any; }) {
    this.message = message;
  }

  message: string;
  severityLevel?: SeverityLevel;
  properties?: { [key: string]: any; };
}
