<h2 class="grey-font" style="margin:0; padding-left:17px;">Vendors</h2>
<div [formGroup]="searchVendorsForm" class="search-container" style="border:none;margin-left: -10px; width:400px;">
  <div class="container-vertical">
    <div>
      <mat-form-field style="width:100%; padding-left: 10px;">
        <mat-label>Search Vendors</mat-label>
        <input id="searchField" class="form-control" formControlName="searchField" required matInput
          (keyup.enter)="searchVendors()">
        <button mat-button *ngIf="searchField.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearVendorSearch()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="searchField.hasError('required')">
          Search is required.
        </mat-error>
        <mat-error *ngIf="searchField.hasError('maxlength')">
          Search value must be less than 51 characters.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-radio-group aria-label="Select an option" color="primary" formControlName="searchType">
        <mat-radio-button *ngFor="let searchType of searchTypes | enumToArray" [value]="searchType"
          style="padding-left:10px;">
          {{searchType | humanReadable}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="search-button">
    <button mat-raised-button color="primary" class="main-button" (click)="searchVendors()">Search</button>
  </div>
</div>