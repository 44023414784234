import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';

// Layouts
import { EobListComponent } from './components/eob-list/eob-list.component';
import { HomeComponent } from './components/home/home.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { AdminGuard } from './guards/admin.guard';
import { UserDefaultRouteGuard } from './guards/user-default-route.guard';
import { EobOktaCallbackComponent } from './components/eob-okta-callback/eob-okta-callback.component';
import { UserVendorReportComponent } from './components/user-vendor-report/user-vendor-report.component';
import { ManageUserEmailComponent } from './components/manange-user-email/manage-user-email.component';
import { EobOktaAuthGuard } from './guards/eob-okta-auth-guard';

export const routes: Routes = [
  {
    path: 'myeob',
    component: EobListComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'myeor',
    component: EobListComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'user/manage-email',
    component: ManageUserEmailComponent,
    canActivate: [EobOktaAuthGuard]
  },
  {
    path: 'authorizationcode/callback',
    component: EobOktaCallbackComponent,
  },
  {
    path: 'reports',
    component: UserVendorReportComponent,
    canActivate: [EobOktaAuthGuard, AdminGuard]
  },
  {
    path: 'user/manage',
    component: ManageUserComponent,
    canActivate: [EobOktaAuthGuard, AdminGuard]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [UserDefaultRouteGuard],
  },
  {
    path: "**",
    redirectTo:''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
