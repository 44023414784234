import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Vendor } from '../model/vendor';
import { User } from '../model/User';
import { VendorMappingChange } from '../model/VendorMappingChange';
import { SearchQuery, VendorSearchType, UserSearchType } from '../model/SearchQuery';
import { Eob } from '../model/Eob';
import { DatePipe } from '@angular/common';
import { PaymentType } from '../model/PaymentType';
import { AdminRequest } from '../model/AdminRequest';
import { VendorAssociation } from '../model/VendorAssociation';
import { UserEmailPreference } from '../model/UserEmailPreference';
import { EobSummaryResponse } from "../model/EobSummaryResponse";
import { AppConfiguration } from '../model/configuration';
import { BrandingService } from './branding.service';

@Injectable({
  providedIn: 'root'
})
export class EobApiService {
  apiRoot: string;
  public accessToken: string;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private configuration: AppConfiguration,
    private branding: BrandingService) {
    const apiBaseUri = this.configuration.eobApiBaseUri;

    this.apiRoot = apiBaseUri.endsWith('/') ? apiBaseUri.substring(0, apiBaseUri.length - 1) : apiBaseUri;
  }

  searchUsers(search: SearchQuery<UserSearchType>): Observable<User[]> {
    const options = { headers: this.authorizationHeader };

    return this.http.get<User[]>(`${this.apiRoot}/user?searchField=${search.searchField}&searchType=${search.searchType}`, options);
  }

  getUserAssociatedVendors(userId: string): Observable<Vendor[]> {
    const options = {
      headers: this.authorizationHeader
    };

    return this.http.get<Vendor[]>(`${this.apiRoot}/user/${userId}/vendors`, options);
  }

  getUserVendorList(userId: string): Observable<VendorAssociation[]> {
    const options = {
      headers: this.authorizationHeader
    };

    return this.http.get<VendorAssociation[]>(`${this.apiRoot}/vendor/${userId}`, options);
  }

  getEobSummaries(oktaUserId: string, vendorId: string, startDate: Date, endDate: Date, paymentType: PaymentType): Observable<EobSummaryResponse> {
    // The date range should fully include all payments on both the start and end date.
    // The end date provided by the UI will likely be at 12: 00am
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    const requestParameters = new HttpParams()
      .set('oktaUserId', oktaUserId)
      .set('vendorId', vendorId)
      .set('startDate', this.datePipe.transform(startDate, 'MM-dd-yyyy'))
      .set('endDate',
        this.datePipe.transform(endDate, 'MM-dd-yyyy HH:mm:ss')) // Format the date string to fully include time of day to api constraints
      .set('paymentType', paymentType.toString().replace(" ", ""))
      .set('eobs', (!this.branding.isEor).toString());

    const options = {
      headers: this.authorizationHeader,
      params: requestParameters
    };

    return this.http.get<EobSummaryResponse>(`${this.apiRoot}/eob/summaries`, options);
  }

  getEobClaimantSummaries(oktaUserId: string, startDate: Date, endDate: Date, paymentType: PaymentType): Observable<EobSummaryResponse> {
    // The date range should fully include all payments on both the start and end date.
    // The end date provided by the UI will likely be at 12: 00am
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    const requestParameters = new HttpParams()
      .set('oktaUserId', oktaUserId)
      .set('startDate', this.datePipe.transform(startDate, 'MM-dd-yyyy'))
      .set('endDate',
        this.datePipe.transform(endDate, 'MM-dd-yyyy HH:mm:ss')) // Format the date string to fully include time of day to api constraints
      .set('paymentType', paymentType.toString());

    const options = {
      headers: this.authorizationHeader,
      params: requestParameters
    };

    return this.http.get<EobSummaryResponse>(`${this.apiRoot}/eob/ClaimantSummaries`, options);
  }

  searchVendors(search: SearchQuery<VendorSearchType>, oktaUserId: string): Observable<VendorAssociation[]> {
    const requestParameters = new HttpParams()
      .set('searchField', search.searchField)
      .set('searchType', search.searchType.toString())
      .set('oktaUserId', oktaUserId);

    const options = {
      headers: this.authorizationHeader,
      params: requestParameters
    };

    return this.http.get<VendorAssociation[]>(`${this.apiRoot}/vendor`, options);
  }

  createUser(user: User): Observable<object> {
    return this.http.post(`${this.apiRoot}/user`, user, { headers: this.authorizationHeader });
  }

  downloadFile(recordId: number, isClaimantUser: boolean = false): Observable<HttpResponse<Blob>> {
    const requestParameters = new HttpParams()
      .set('recordId', recordId.toString());

    const options = {
      params: requestParameters,
      headers: this.authorizationHeader,
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };

    if (isClaimantUser)
      return this.http.get<HttpResponse<Blob>>(`${this.apiRoot}/eob/claimantpdf`, options);

    if (this.branding.isEor)
      return this.http.get<HttpResponse<Blob>>(`${this.apiRoot}/eob/eorrpspdf`, options);

    return this.http.get<HttpResponse<Blob>>(`${this.apiRoot}/eob/pdf`, options);
  }

  addVendorAssociation(vendorMappingChange: VendorMappingChange): Observable<Object> {
    return this.http.post(`${this.apiRoot}/user/vendor`, vendorMappingChange, { headers: this.authorizationHeader });
  }

  removeVendorAssociation(vendorMappingChange: VendorMappingChange): Observable<Object> {
    const options = {
      headers: this.authorizationHeader,
      body: vendorMappingChange
    };

    return this.http.delete(`${this.apiRoot}/user/vendor`, options);
  }

  userVendorAssociationReport(): Observable<HttpResponse<Blob>> {
    return this.http.get<HttpResponse<Blob>>(`${this.apiRoot}/user/report`,
      {
        headers: this.authorizationHeader, responseType: 'blob' as 'json', observe: 'response' as 'body'
      });
  }

  removeUser(user: User) {
    return this.http.delete(`${this.apiRoot}/user/${user.oktaUserId}/${user.email}`, { headers: this.authorizationHeader });
  }

  giveAdminRights(oktaUserId: string): Observable<object> {
    const request = new AdminRequest(oktaUserId);
    return this.http.post(`${this.apiRoot}/user/giveAdmin`, request, { headers: this.authorizationHeader });
  }

  giveClaimantRights(oktaUserId: string): Observable<object> {
    const request = new AdminRequest(oktaUserId);
    return this.http.post(`${this.apiRoot}/user/giveClaimant`, request, { headers: this.authorizationHeader });
  }

  removeAdminRights(oktaUserId: string): Observable<object> {
    const request = new AdminRequest(oktaUserId);
    return this.http.post(`${this.apiRoot}/user/removeAdmin`, request, { headers: this.authorizationHeader });
  }

  removeClaimantRights(oktaUserId: string): Observable<object> {
    const request = new AdminRequest(oktaUserId);
    return this.http.post(`${this.apiRoot}/user/removeClaimant`, request, { headers: this.authorizationHeader });
  }

  getAdminRights(oktaUserId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiRoot}/user/${oktaUserId}/admin`, { headers: this.authorizationHeader });
  }

  getClaimantRights(oktaUserId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiRoot}/user/${oktaUserId}/claimant`, { headers: this.authorizationHeader });
  }

  updateUserEmailPreference(oktaUserId: string, receiveEmail: boolean) {
    const payload = new UserEmailPreference();
    payload.receiveEmail = receiveEmail;
    payload.oktaUserId = oktaUserId;

    const options = {
      headers: this.authorizationHeader
    };

    return this.http.post<object>(`${this.apiRoot}/user/email-preference`, payload, options);
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${this.apiRoot}/user/current`, { headers: this.authorizationHeader });
  }

  getUser(oktaUserId: string): Observable<User> {
    return this.http.get<User>(`${this.apiRoot}/user/${oktaUserId}`, { headers: this.authorizationHeader });
  }

  updateUserLastLogin() {
    return this.http.post(`${this.apiRoot}/user/lastlogin`, null, { headers: this.authorizationHeader });
  }

  get authorizationHeader() {
    return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.accessToken}` });
  }
}
