import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { AppComponent } from './components/app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { EobListComponent } from './components/eob-list/eob-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { MatSelectModule } from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ToastrModule } from 'ngx-toastr';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';

// Security
import { OKTA_CONFIG, OktaAuthModule, OktaAuthService } from '@okta/okta-angular';
import { CommonModule, DatePipe } from '@angular/common';
import { EnumToArrayPipe } from './pipes/EnumToArrayPipe';
import { SearchVendorsComponent } from './components/search-vendors/search-vendors.component';
import { SearchUsersComponent } from './components/search-users/search-users.component';
import { SelectUserComponent } from './components/select-user/select-user.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { UserVendorListComponent } from './components/user-vendor-list/user-vendor-list.component';
import { UserVendorReportComponent } from './components/user-vendor-report/user-vendor-report.component';
import {MatButtonModule} from '@angular/material/button';
import { AdminGuard } from './guards/admin.guard';
import { UserDefaultRouteGuard } from './guards/user-default-route.guard';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ConfirmRemoveDialogComponent } from './components/select-user/confirm-remove-dialog.component';
import {ManageAdminComponent} from './components/manage-admin/manage-admin.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ManageUserEmailComponent } from './components/manange-user-email/manage-user-email.component';
import { RemoveUserComponent } from './components/remove-user/remove-user.component';
import { EobOktaCallbackComponent } from './components/eob-okta-callback/eob-okta-callback.component';
import { AuthenticationErrorService } from './services/AuthenticationErrorService';
import { windowProvider, WindowToken } from './window';
import { LoadingComponent } from './components/loading/loading.component';
import { HumanReadablePipe } from './pipes/HumanReadablePipe';
import { ApplicationInsightsService } from './services/ApplicationInsightsService';
import { GlobalErrorHandler } from './GlobalErrorHandler';
import { OidcSettings } from './model/oidcSettings';
import { EobOktaAuthGuard } from './guards/eob-okta-auth-guard';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    EobListComponent,
    EnumToArrayPipe,
    HumanReadablePipe,
    SearchVendorsComponent,
    UserVendorListComponent,
    CreateUserComponent,
    SearchUsersComponent,
    SelectUserComponent,
    ManageUserComponent,
    RemoveUserComponent,
    UserVendorReportComponent,
    ConfirmRemoveDialogComponent,
    ManageAdminComponent,
    ManageUserEmailComponent,
    EobOktaCallbackComponent,
    LoadingComponent
  ],
    imports: [
        OktaAuthModule,
        BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatFormFieldModule,
        CommonModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
          positionClass: 'toast-bottom-right'
        }),
        MatSelectModule,
        MatRadioModule,
        MatButtonModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatCardModule,
        MatTooltipModule,
        MatIconModule
    ],
  providers: [
    {
      provide: OKTA_CONFIG,
      useFactory: (oidcSettings) => oidcSettings,
      deps: [OidcSettings]
    },
    AdminGuard,
    UserDefaultRouteGuard,
    EobOktaAuthGuard,
    DatePipe,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    AuthenticationErrorService,
    { provide: WindowToken, useFactory: windowProvider },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmRemoveDialogComponent],
})

export class AppModule {
}
