export class Vendor {
  vendorId: string;
  firmName: string;
  taxId: string;

  setVendorId(vendorId: string): Vendor {
    this.vendorId = vendorId;

    return this;
  }
}
