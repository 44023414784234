import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EobApiService } from '../../services/EobApiService';
import { SearchQuery, UserSearchType } from '../../model/SearchQuery';
import { User } from '../../model/User';
import { EobApiErrorService } from '../../services/EobApiErrorService';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html'
})
export class SearchUsersComponent {
  searchForm: FormGroup;
  searchTypes = UserSearchType;

  @Output() resultsFound: EventEmitter<User[]> = new EventEmitter();

  constructor(private userApiService: EobApiService,
    private apiErrorService: EobApiErrorService,
    private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      'searchField': new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      'searchType': new FormControl('', [
      ])
    });

    // Set form defaults
    this.searchForm.patchValue({ searchType: 'Email'});
  }

  get searchField() { return this.searchForm.get('searchField'); }

  get searchType() { return this.searchForm.get('searchType'); }

  searchUsers() {
    if (!this.searchForm.valid) {
      // Touch each control on the form so it triggers validation UI
      Object.keys(this.searchForm.controls).forEach(field => {
        const control = this.searchForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    } else {
    const searchQuery = new SearchQuery<UserSearchType>();

    searchQuery.searchField = this.searchField.value;
    searchQuery.searchType = this.searchType.value;

    this.userApiService.searchUsers(searchQuery).subscribe(data => {
      if (data.length === 0) {
        let message = 'No users found with ';
        switch (searchQuery.searchType.toString()) {
          case 'Email':
            message += 'an email ';
            break;
          case 'Name':
            message += 'a name ';
            break;
          case 'VendorId':
            message += 'an associated vendor with a vendor ID ';
            break;
          default:
            break;
        }
        message += 'matching ' + `"` + searchQuery.searchField + `".`;
        this.toastrService.warning(message, 'Search returned no users');
      } else {
        this.resultsFound.emit(data);
      }
    },
      errorResponse => {
        this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid User Search');
      });
    }
  }

  clearSearch() {
    this.searchField.reset();
    this.resultsFound.emit(null);
  }
}
