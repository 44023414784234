import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { OidcSettings } from './app/model/oidcSettings';
import { AppConfiguration } from './app/model/configuration';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

fetch(getBaseUrl() + 'api/clientconfiguration/').then(async response => {
  const appConfiguration = await response.json();

  const apiBaseUri = appConfiguration.eobApiBaseUri;

  appConfiguration.eobApiBaseUri = apiBaseUri.endsWith('/')
    ? apiBaseUri.substring(0, apiBaseUri.length - 1)
    : apiBaseUri;

  if (!environment.production)
    console.log(appConfiguration.oidcSettings.redirectUri);
  appConfiguration.oidcSettings.redirectUri = `${window.location.href}authorizationcode/callback`;
  if (!environment.production)
    console.log(appConfiguration.oidcSettings.redirectUri);

  const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: OidcSettings, useValue: appConfiguration.oidcSettings, deps: [] },
    { provide: AppConfiguration, useValue: appConfiguration, deps:[] }
  ];  

  platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.log(err));
}).catch(err => console.log(err));
