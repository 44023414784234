<div class="sidebar-layout">
  <div class="sidebar">
    <app-select-user #userList (userSelected)="selectUser($event)" (createUser)="handleCreateUser($event)"></app-select-user>
  </div>
  <div *ngIf="!showCreateUser && selectedUser === undefined" class="sidebar-rightpane manage-user-container manage-user-splash">
    <mat-card class="grey-font">
      <mat-card-title class="flex">
        <span class="material-icons mat-card-icon">
          search 
        </span>
        Search
      </mat-card-title>
      <mat-card-content>
        Search users by email, name, or Vendor Id.
      </mat-card-content>
    </mat-card>
    <span class="material-icons icon-spacer">
      double_arrow
    </span>
    <mat-card class="grey-font">
      <mat-card-title class="flex">
        <span class="material-icons mat-card-icon">
          touch_app 
        </span>
        Select
      </mat-card-title>
      <mat-card-content>
      Select a user. 
      </mat-card-content>
    </mat-card>
    <span class="material-icons icon-spacer">
      double_arrow
    </span>
    <mat-card class="grey-font">
      <mat-card-title class="flex">
        <span class="material-icons mat-card-icon">
          edit 
        </span>
        Edit
      </mat-card-title>
      <mat-card-content>
        Edit a user's vendor mappings, permission level or remove them.
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="showCreateUser" class="sidebar-rightpane">
    <app-create-user (userCreated)="userCreated($event)"></app-create-user>
  </div>
  <div *ngIf="selectedUser !== undefined && !showCreateUser" class="sidebar-rightpane manage-user-container container-vertical">
    <mat-card class="fill-width">
      <mat-card-header class="grey-font user-header-container">
        <mat-card-title>
          {{selectedUser.firstName}} {{selectedUser.lastName}}
        </mat-card-title>
        <mat-card-subtitle>
          {{selectedUser.email}}
        </mat-card-subtitle>
        <div class="flex">
          <div style="margin-top: 10px; padding-right: 21px;">
            <button mat-raised-button
                    class="main-button"
                    color="primary"
                    matTooltip="See the {{brandingService.title}} page as '{{selectedUser.firstName}} {{selectedUser.lastName}}' would"
                    (click)="viewEobSummariesAs(selectedUser)">
              <span class="material-icons">pageview</span>
              View {{brandingService.title}} As
            </button>
          </div>
          <app-remove-user style="margin-top: 10px; padding-right: 5px;" [selectedUser]="selectedUser" (userRemoved)="removeUser($event)"></app-remove-user>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div [hidden]="loading">
          <app-manage-admin #manageAdmin (userRightsIsAdminChanged)="onUserAdminRightsChange($event)" [(SelectedUser)] ="selectedUser"></app-manage-admin>
          <app-user-vendor-list #userVendorList [selectedUser]="selectedUser" (vendorSearchCleared)="respondToVendorSearchCleared()" class="fill-width" *ngIf="!selectedUser.isClaimantUser"></app-user-vendor-list>
        </div>
        <app-loading [hidden]="!loading"></app-loading>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<!--<pre>{{selectedUser | json}}</pre>-->
