import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { UserService } from '../../services/UserService';
import { AuthenticationErrorService } from '../../services/AuthenticationErrorService';
import { Subscription } from 'rxjs';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  isAuthenticated: boolean | undefined;
  authenticationHasFailed: boolean | undefined;
  isAdminUser: boolean | undefined;

  authenticationErrorSubscription: Subscription;

  constructor(public oktaAuth: OktaAuthService,
    private userService: UserService,
    private authenticationService: AuthenticationErrorService,
    public brandingService: BrandingService) {
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => {
        this.authenticationHasFailed = false;

        this.isAuthenticated = isAuthenticated;
        this.checkIsAdmin();
      },
      error => this.authenticationHasFailed = true
    );

    this.authenticationErrorSubscription = this.authenticationService.getStatus().subscribe(value => {
      this.authenticationHasFailed = value;
      this.isAuthenticated = false;
    });
  }

  async ngOnInit() {
    this.isAuthenticated = await this.userService.isAuthenticated();
    await this.checkIsAdmin();
  }

  ngOnDestroy() {
    this.authenticationErrorSubscription.unsubscribe();
  }

  private async checkIsAdmin() {
    this.isAdminUser = await this.userService.isAdminUser();
  }

  login() {
    this.oktaAuth.loginRedirect();
  }

  logout() {
    this.oktaAuth.logout('/');
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  handleAuthenticationFailure(error) {
    this.authenticationHasFailed = true;
  }

  getUserName(): string {
    return `Logout user : ${this.userService.oktaUser ? this.userService.oktaUser.name : ""} - ${this.userService.isClaimantUser ? "C" : "V"}`;
  }
}
