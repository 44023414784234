import { OidcSettings } from './oidcSettings';
import { Injectable } from '@angular/core';

export class AppConfiguration {
  public eobApiBaseUri: string;
  public oidcSettings: OidcSettings;
  public userManagementSystemName: string;
  public applicationInsightsInstrumentationKey: string;

  setEobApiBaseUri(uri: string) {
    this.eobApiBaseUri = uri;

    return this;
  }
}
