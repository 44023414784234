export class SearchQuery<TSearchType> {
  searchField: string;
  searchType: UserSearchType;
}

export enum UserSearchType {
  Email,
  Name,
  VendorId
}

export enum VendorSearchType {
  FirmName,
  VendorId,
  TaxId
}
