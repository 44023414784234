<div class="sidebar-layout">
  <div class="sidebar">
    <div class="filter-container">
      <h1 class="sidebar-header">Date Range</h1>
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDatePicker" [formControl]="startDate" (dateChange)="getEobs(oktaUserId)">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error *ngIf="startDateError !== '' && startDateError != undefined">{{startDateError}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDatePicker" [formControl]="endDate" (dateChange)="getEobs(oktaUserId)">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="endDateError !== '' && endDateError != undefined">{{endDateError}}</mat-error>
      </mat-form-field>
    </div>
    <div class="filter-container">
      <h1 class="sidebar-header">Payment Type</h1>
      <mat-radio-group aria-label="Select an option" color="primary" [formControl]="paymentType"
                       (change)="selectPaymentType()" style="padding-left: 5px; margin-top: 10px;">
        <span *ngFor="let paymentType of paymentTypes | enumToArray; let i = index">
          <mat-radio-button [value]="paymentType" style="padding-left: 20px;">
            {{paymentType}}
          </mat-radio-button>
          <br *ngIf="i == 0" />
        </span>

      </mat-radio-group>
    </div>
    <div class="filter-container" *ngIf="!displayAsClaimant">
      <h1 class="sidebar-header">
        Vendor
      </h1>
      <mat-form-field style="margin-top: -14px;">
        <mat-label>Choose a vendor</mat-label>
        <mat-select (selectionChange)="selectVendor($event)" [value]="vendors[0]">
          <mat-option *ngFor="let vendor of vendors" [value]="vendor">
            {{vendor.firmName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="eob-container" [hidden]="loading">
    <mat-form-field class="eob-filter">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter()" placeholder="Search by name, date, etc." [(ngModel)]="searchValue">
      <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="mat-elevation-z8" style="width: 100%;">
      <table mat-table [dataSource]="dataSource" class="eob-list" matSort>
        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef> Download </th>
          <td mat-cell *matCellDef="let eob"> <i (click)="download(eob)" class="material-icons clickable" style="color:#ff9800">cloud_download</i> </td>
        </ng-container>
        <ng-container matColumnDef="claimantSSN">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SSN </th>
          <td mat-cell *matCellDef="let eob"> {{eob.claimantSSN}} </td>
        </ng-container>
        <ng-container matColumnDef="claimNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Claim # </th>
          <td mat-cell *matCellDef="let eob"> {{eob.claimNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="claimantName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Claimant Name </th>
          <td mat-cell *matCellDef="let eob"> {{eob.claimantName}} </td>
        </ng-container>
        <ng-container matColumnDef="claimantBirthDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Birth </th>
          <td mat-cell *matCellDef="let eob"> {{eob.claimantBirthDate | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="employerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Employer Name </th>
          <td mat-cell *matCellDef="let eob"> {{eob.employerName}} </td>
        </ng-container>
        <ng-container matColumnDef="paymentDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Date </th>
          <td mat-cell *matCellDef="let eob"> {{eob.paymentDate | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="paymentMethod">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let eob"> {{adjustedPaymentMethod(eob.paymentMethod)}} </td>
        </ng-container>
        <ng-container matColumnDef="paymentTotal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
          <td mat-cell *matCellDef="let eob"> {{eob.paymentTotal | currency}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
  <app-loading [hidden]="!loading"></app-loading>
</div>
