import { Component, ViewChild } from '@angular/core';
import { Eob } from '../../model/Eob';
import { EobApiService } from '../../services/EobApiService';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { Vendor } from '../../model/vendor';
import { OktaAuthService, UserClaims } from '@okta/okta-angular';
import { MatSelectChange } from '@angular/material/select';
import { PaymentType } from '../../model/PaymentType';
import { FileService } from '../../services/fileService';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd, ParamMap } from '@angular/router';
import { UserService } from '../../services/UserService';
import { ApplicationInsightsService } from '../../services/ApplicationInsightsService';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'eob-list',
  templateUrl: 'eob-list.component.html',
})
export class EobListComponent {
  displayedColumns: string[] = !this.brandingService.isEor ?
    ['download', 'claimantSSN', 'claimNumber', 'claimantName', 'claimantBirthDate', 'employerName', 'paymentDate', 'paymentTotal'] :
    ['download', 'claimantSSN', 'claimNumber', 'claimantName', 'paymentMethod', 'paymentDate', 'paymentTotal'];
  startDate: FormControl;
  endDate: FormControl;
  paymentType: FormControl;
  dataSource: MatTableDataSource<Eob>;
  vendors: Vendor[];
  selectedVendor: Vendor;
  oktaUserId: string;
  displayAsClaimant: boolean = false;
  oktaUser: UserClaims;
  paymentTypes = !this.brandingService.isEor ? [PaymentType.All, PaymentType.ACH, PaymentType.Check] : [PaymentType.All, PaymentType.ACH, PaymentType.Check, PaymentType.NoPayment];
  startDateError: string;
  endDateError: string;
  loading: boolean;
  searchValue: string;
  vendorDefault: Vendor;
  get isClaimantUser(): boolean { return this.userService.isClaimantUser }
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private eobApi: EobApiService,
    private eobApiErrorService: EobApiErrorService,
    private oktaAuthService: OktaAuthService,
    private fileService: FileService,
    private userService: UserService,
    private applicationInsightsService: ApplicationInsightsService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private brandingService: BrandingService) {
  }

  async ngOnInit() {
    this.sort.sort(({ id: 'paymentDate', start: 'desc' }) as MatSortable);

    this.vendorDefault = new Vendor();
    this.vendorDefault.firmName = 'All';
    this.vendorDefault.vendorId = '';

    this.vendors = [];
    this.vendors.push(this.vendorDefault);

    this.route.queryParamMap.subscribe(async params => await this.handleOktaUserIdInRoute(params));

    const initDate = new Date();

    initDate.setDate(initDate.getDate() - 30);

    this.startDate = new FormControl(new Date(initDate.toISOString()));
    this.endDate = new FormControl(new Date(new Date().toISOString()));
    this.paymentType = new FormControl('All');

    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = true;
  }

  async getEobs(oktaUserId: string) {
    this.loading = true;
    // When invalid input (e.g. non numeric characters) are entered into the material date picker it nulls out its value
    this.startDateError = '';
    this.endDateError = '';
    this.startDate.setErrors(null);
    this.endDate.setErrors(null);

    if (this.startDate.value == null) {
      this.startDateError = 'The start date must be in MM/DD/YYYY format.';
      this.startDate.setErrors({ 'incorrect': true });
    }

    if (this.endDate.value == null) {
      this.endDateError = 'The end date must be in MM/DD/YYYY format.';
      this.endDate.setErrors({ 'incorrect': true });
    }

    if (this.startDateError !== '' || this.endDateError !== '') {
      this.loading = false;

      return;
    }

    const startDate = new Date(this.startDate.value);
    const endDate = new Date(this.endDate.value);

    if (endDate <= startDate) {
      this.startDateError = 'Start date must be at least one day before End date.';
      this.startDate.setErrors({ 'incorrect': true });

      this.loading = false;

      return;
    }

    const isAdmin = await this.userService.isAdminUser()
    if (isAdmin) {
      this.eobApi.getClaimantRights(oktaUserId).subscribe(isClaimantUser => {
        if (isClaimantUser) {
          //Show Payments for Personal Users
          this.eobApi.getEobClaimantSummaries(oktaUserId, this.startDate.value, this.endDate.value, this.paymentType.value)
            .subscribe(
              response => {
                if (!response.success) {
                  this.eobApiErrorService.handlePartialError('Some data could not be retrieved, please submit a helpdesk ticket.');
                }
                this.dataSource.data = response.eobSummaries;
                this.loading = false;
              },
              errorResponse => {
                this.eobApiErrorService.handleApiErrorResponse(errorResponse, 'Getting Explanation of Benefit Summaries');
                this.dataSource.data = [];
                this.loading = false;
              });
        }
        else {

          //Show Payments for Vendor Related Users
          let vendorId = '';

          if (this.selectedVendor) {
            vendorId = this.selectedVendor.vendorId;
          }

          this.eobApi.getEobSummaries(oktaUserId, vendorId, this.startDate.value, this.endDate.value, this.paymentType.value)
            .subscribe(
              response => {
                if (!response.success) {
                  this.eobApiErrorService.handlePartialError('Some data could not be retrieved, please submit a helpdesk ticket.');
                }
                this.dataSource.data = response.eobSummaries;
                this.loading = false;
              },
              errorResponse => {
                this.eobApiErrorService.handleApiErrorResponse(errorResponse, 'Getting Explanation of Benefit Summaries');
                this.dataSource.data = [];
                this.loading = false;
              });
        }
      })
    }
    else {
      if (this.userService.isClaimantUser) {
        //Show Payments for Personal Users
        this.eobApi.getEobClaimantSummaries(oktaUserId, this.startDate.value, this.endDate.value, this.paymentType.value)
          .subscribe(
            response => {
              if (!response.success) {
                this.eobApiErrorService.handlePartialError('Some data could not be retrieved, please submit a helpdesk ticket.');
              }
              this.dataSource.data = response.eobSummaries;
              this.loading = false;
            },
            errorResponse => {
              this.eobApiErrorService.handleApiErrorResponse(errorResponse, 'Getting Explanation of Benefit Summaries');
              this.dataSource.data = [];
              this.loading = false;
            });
      }
      else {
        //Show Payments for Vendor Related Users
        let vendorId = '';

        if (this.selectedVendor) {
          vendorId = this.selectedVendor.vendorId;
        }

        this.eobApi.getEobSummaries(oktaUserId, vendorId, this.startDate.value, this.endDate.value, this.paymentType.value)
          .subscribe(
            response => {
              if (!response.success) {
                this.eobApiErrorService.handlePartialError('Some data could not be retrieved, please submit a helpdesk ticket.');
              }
              this.dataSource.data = response.eobSummaries;
              this.loading = false;
            },
            errorResponse => {
              this.eobApiErrorService.handleApiErrorResponse(errorResponse, 'Getting Explanation of Benefit Summaries');
              this.dataSource.data = [];
              this.loading = false;
            });
      }
    }
  }

  // Payment Total is a #, potentially with trailing 0s which would mess with how it is filtered naturally
  applyFilter() {
    let searchNumber = Number(this.searchValue);

    if (searchNumber) {
      this.dataSource.filter = searchNumber.toString();
    }
    else {
      this.dataSource.filter = this.searchValue.trim().toLowerCase();
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.searchValue = '';
    this.applyFilter();
  }

  selectVendor(changeEvent: MatSelectChange) {
    this.selectedVendor = changeEvent.value;
    this.getEobs(this.oktaUserId);
  }


  selectPaymentType() {
    this.getEobs(this.oktaUserId);
  }

  download(eob: Eob) {
    this.loading = true;
    this.eobApi.downloadFile(eob.recordId, this.displayAsClaimant).subscribe(
      (response) => {
        try {
          const filename = this.getFileNameFromHttpResponse(response);

          this.fileService.saveFileAs(filename, response.body, 'application/pdf');
        } catch (e) {
          this.applicationInsightsService.logError(e);

          this.toastrService.error('Unable to save Explanation of Benefit PDF file.');

          this.loading = false;
        }
      },
      (errorResponse) => this.eobApiErrorService.handleApiErrorResponse(errorResponse, 'Getting Report PDF'),
      () => this.loading = false);
  }

  getFileNameFromHttpResponse(httpResponse) {
    var fileName = "";
    try {
      const contentDispositionHeader = httpResponse.headers.get('Content-Disposition').toString();

      const headerValues = contentDispositionHeader.split(';');

      const map: { [name: string]: string; } = {};

      for (const headerKvP of headerValues) {
        const kvp = headerKvP.trim().split('=');

        map[kvp[0]] = kvp[1];
      }

      fileName = map['filename'];
    }
    catch (e) {
      fileName = "TribalFirstReport.pdf"
    }

    return fileName.replace(/"/g, '');
  }

  public adjustedPaymentMethod(method: string): string {
    return method == "Paper Transaction" ? "No Payment" : method
  }

  async handleOktaUserIdInRoute(params: ParamMap) {
    this.oktaUserId = params.get('oktaUserId');
    const isAdmin = await this.userService.isAdminUser()
    const oktaUserIdPresentAndUserIsNotAdmin = this.oktaUserId != null && !isAdmin;
    const oktaUserIdPresentAndUserIsAdmin = this.oktaUserId != null && isAdmin;
    const oktaUserIdIsNotPresent = this.oktaUserId == null;

    this.oktaUser = await this.oktaAuthService.getUser();

    if (oktaUserIdPresentAndUserIsNotAdmin || oktaUserIdIsNotPresent) {
      this.oktaUserId = this.oktaUser.sub;
      this.displayAsClaimant = this.isClaimantUser
    }

    if (oktaUserIdPresentAndUserIsAdmin) {
      this.eobApi.getClaimantRights(this.oktaUserId).subscribe(
        (isClaimantUser) => {this.displayAsClaimant = isClaimantUser});
    }

    this.eobApi.getUserAssociatedVendors(this.oktaUserId).subscribe(
      (response) => { this.vendors = [this.vendorDefault].concat(response) },
      (errorResponse) => this.eobApiErrorService.handleApiErrorResponse(errorResponse, 'Getting Associated Vendors'));

    this.getEobs(this.oktaUserId);
  }
}
