import { ErrorHandler, Injectable} from '@angular/core';
import { ApplicationInsightsService } from './services/ApplicationInsightsService';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private applicationInsightsService: ApplicationInsightsService) {
  }

  handleError(error) {
    this.applicationInsightsService.logError(error, {}, {});

     // IMPORTANT: Rethrow the error otherwise it gets swallowed
     throw error;
  }

}
