<div class="container" style="max-width: 400px;">
    <mat-card>
      <mat-card-title style="text-align: center;">
        Welcome to {{this.brandingService.title}}!
      </mat-card-title>
        <mat-card-subtitle>
           Please log in to view your Explanation of Benefits list.
        </mat-card-subtitle>
    </mat-card>
</div>
