import { Injectable } from '@angular/core';
import { OktaAuthService, UserClaims } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public oktaUser: UserClaims
  private _isClaimantUser: boolean | undefined
  private _isAuthenticated: boolean | undefined
  get isClaimantUser() { return this._isClaimantUser }


  constructor(
    private oktaAuth: OktaAuthService) {
    this.isAdminUser()
  }


  async isAuthenticated(): Promise<boolean> {
    var test = this.oktaAuth.isAuthenticated();
    return this.oktaAuth.isAuthenticated();
  }

  async isAdminUser(): Promise<boolean> {
    let isAdmin = false;

    if (await this.oktaAuth.isAuthenticated()) {
      this.oktaUser = await this.oktaAuth.getUser();
      if (this.oktaUser != null) {
        isAdmin = this.oktaUser.IsMyEobAdmin != null || this.oktaUser.IsMyEobAdmin != undefined ;
        var groupArray: string[] = [];
        groupArray.push(this.oktaUser.IsMyEobUser != null ? this.oktaUser.IsMyEobUser : this.oktaUser.IsMyEobAdmin);
        this._isClaimantUser = groupArray.includes("MyEob Users - Claimant") // || this.oktaUser.IsMyEobAdmin != undefined
      }
    }




    return isAdmin;
  }


}
