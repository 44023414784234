<header>
  <nav class="{{brandingService.cssClass}} navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3" id="main-nav" >
      <a class="navbar-brand" [routerLink]="['/']"><img id="nav-img" src="{{brandingService.logoUrl}}" alt=""></a>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
           [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item"
              *ngIf="isAuthenticated"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="brandingService.subdomain">{{brandingService.title}}</a>
          </li>
          <li class="nav-item"
              *ngIf="isAuthenticated"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['user/manage-email']">Manage Email Preference</a>
          </li>
          <li class="nav-item"
              *ngIf="isAuthenticated"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['user/manage']" *ngIf="isAdminUser">Manage Users</a>
          </li>
          <li class="nav-item"
              *ngIf="isAuthenticated"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['reports']" *ngIf="isAdminUser">Reports</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-dark" co href="{{brandingService.helpEmail}}">Help</a>
          </li>
          <li class="nav-item" *ngIf="!authenticationHasFailed && !isAuthenticated" (click)="login()"><a class="nav-link text-dark" href="#">Login</a></li>
          <li class="nav-item" *ngIf="isAuthenticated || authenticationHasFailed" (click)="logout()"  [title]="getUserName()"><a class="nav-link text-dark" href="#">Logout</a></li>
        </ul>
      </div>
  </nav>
</header>
