export class User {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
  isClaimantUser: boolean;
  isAdmin: boolean;
  oktaUserId: string;
  receiveEmail: boolean;

  setId(id: number) {
    this.id = id;

    return this;
  }

  setOktaUserId(oktaUserId: string) {
    this.oktaUserId = oktaUserId;

    return this;
  }

  setReceiveEmail(receiveEmail: boolean) {
    this.receiveEmail = receiveEmail;

    return this;
  }
}
