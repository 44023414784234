<h1 class="sidebar-header" style="padding-top:20px; margin:0;">Users</h1>
<div [formGroup]="searchForm" class="search-container">
  <div class="container-vertical">
    <div>
      <mat-form-field style="width:100%; padding-left: 10px;" (keyup.enter)="searchUsers()">
        <mat-label>Search Users</mat-label>
        <input id="searchField" class="form-control" formControlName="searchField" required matInput>
        <button mat-button *ngIf="searchField.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="searchField.hasError('required')">
          Search is required.
        </mat-error>
        <mat-error *ngIf="searchField.hasError('maxlength')">
          Search value must be less than 51 characters.
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-radio-group aria-label="Select an option" color="primary" formControlName="searchType">
        <mat-radio-button *ngFor="let searchType of searchTypes | enumToArray" [value]="searchType"
          style="padding-left:10px;">
          {{searchType | humanReadable}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="search-button">
    <button mat-raised-button color="primary" class="main-button" (click)="searchUsers()">Search</button>
  </div>
</div>