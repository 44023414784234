import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EobApiService } from '../../services/EobApiService';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../model/User';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html'
})
export class CreateUserComponent {
  createUserForm: FormGroup;
  user: User;
  loading: boolean;
  @Output() userCreated: EventEmitter<boolean> = new EventEmitter();

  constructor(private userApiService: EobApiService,
    private toastr: ToastrService,
    private apiErrorService: EobApiErrorService,
    private brandingService: BrandingService) {
    this.user = new User();
  }

  ngOnInit(): void {
    this.createUserForm = new FormGroup({
      'firstName': new FormControl(this.user.firstName, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      'lastName': new FormControl(this.user.lastName, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      'emailAddress': new FormControl(this.user.email, [
        Validators.required,
        Validators.email
      ]),
      'isClaimantUser': new FormControl(false),
      'isAdmin': new FormControl(false)
    });
  }

  get firstName() { return this.createUserForm.get('firstName'); }

  get lastName() { return this.createUserForm.get('lastName'); }

  get emailAddress() { return this.createUserForm.get('emailAddress'); }

  get isClaimantUser() { return this.createUserForm.get('isClaimantUser'); }
  get isAdmin() { return this.createUserForm.get('isAdmin'); }
  get isEOR() { return this.brandingService.isEor; }

  createUser() {
    if (!this.createUserForm.valid) {
      // Touch each control on the form so it triggers validation UI
      Object.keys(this.createUserForm.controls).forEach(field => {
        const control = this.createUserForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    } else {
      this.loading = true;
      const user = new User();
      user.firstName = this.firstName.value;
      user.lastName = this.lastName.value;
      user.email = this.emailAddress.value;
      user.isClaimantUser = this.isClaimantUser.value;
      user.isAdmin = this.isAdmin.value;

      this.userApiService.createUser(user).subscribe(
        data => {
          this.toastr.success(`${user.firstName} ${user.lastName} has been created.`, '');
          this.userCreated.emit(true);
        },
        errorResponse => {
          this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid User');
          this.loading = false;
        },
        () => this.loading = false
      );
    }
  }
}
