import { Component, Pipe, PipeTransform, Output, EventEmitter, Input, SimpleChange } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SearchQuery, VendorSearchType } from '../../model/SearchQuery';
import { Vendor } from '../../model/vendor';
import { User } from '../../model/User';
import { EobApiService } from '../../services/EobApiService';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { VendorAssociation } from 'src/app/model/VendorAssociation';

@Component({
  selector: 'app-search-vendors',
  templateUrl: './search-vendors.component.html'
})
export class SearchVendorsComponent {
  searchVendorsForm: FormGroup;
  searchTypes = VendorSearchType;
  @Input() selectedUser: User;
  @Output() searching: EventEmitter<boolean> = new EventEmitter();
  @Output() vendorsFound: EventEmitter<VendorAssociation[]> = new EventEmitter();
  @Output() searchCleared: EventEmitter<boolean> = new EventEmitter();

  constructor(private userApiService: EobApiService,
    private apiErrorService: EobApiErrorService,
    private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.searchVendorsForm = new FormGroup({
      'searchField': new FormControl('', [
        Validators.required,
        Validators.maxLength(80)
      ]),
      'searchType': new FormControl('FirmName', [
      ])
    });

    this.searchVendorsForm.patchValue({ searchType: 'FirmName' });
  }

  // Triggered by changes to bound items (e.g. selectedUser Input)
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const change: SimpleChange = changes['selectedUser'];

    // When previousValue is undefined it means the page is loaded directly
    // from an Okta user ID and so there is not previous entry in searchField
    if (change.previousValue === undefined) {
      return;
    }

    if (change != null) {
      this.searchField.reset();
    }
  }

  get searchField() { return this.searchVendorsForm.get('searchField'); }

  get searchType() { return this.searchVendorsForm.get('searchType'); }

  searchVendors() {
    if (!this.searchVendorsForm.valid) {
      // Touch each control on the form so it triggers validation UI
      Object.keys(this.searchVendorsForm.controls).forEach(field => {
        const control = this.searchVendorsForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    } else {
      this.searching.emit(true);
      const vendorSearchQuery = new SearchQuery<VendorSearchType>();

      vendorSearchQuery.searchField = this.searchField.value;
      vendorSearchQuery.searchType = this.searchType.value;

      this.userApiService.searchVendors(vendorSearchQuery, this.selectedUser.oktaUserId).subscribe(data => {
        if (data.length > 0) {
          this.vendorsFound.emit(data);
        } else {
          let message = 'No vendors found with ';
          switch (vendorSearchQuery.searchType.toString()) {
            case 'FirmName':
              message += 'a firm name ';
              break;
            case 'TaxId':
              message += 'a tax ID ';
              break;
            case 'VendorId':
              message += 'a vendor ID ';
              break;
            default:
              break;
          }
          message += 'matching ' + `"` + vendorSearchQuery.searchField + `".`;
          this.toastrService.warning(message, 'Vendor search returned no results');
        }
      },
      errorResponse => {
        this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid Vendor Search');
        this.searching.emit(false);
      },
      () => {
        this.searching.emit(false);
      });
    }
  }

  clearVendorSearch() {
    this.searchField.reset();
    this.searchCleared.emit(true);
  }
}
