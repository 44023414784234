<mat-card [hidden]="loading">
  <mat-card-title class="grey-font">
    Add a new user
  </mat-card-title>
  <mat-card-content>
    <div [formGroup]="createUserForm">
      <div>
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input id="firstName" class="form-control" formControlName="firstName" matInput (keyUp.enter)="createUser()">
          <button mat-button *ngIf="firstName.value" matSuffix mat-icon-button aria-label="Clear" (click)="firstName.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="firstName.hasError('required')">
            First Name is required.
          </mat-error>
          <mat-error *ngIf="firstName.hasError('maxlength')">
            Name must be less than 51 characters.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input id="lastName" class="form-control" formControlName="lastName" matInput (keyUp.enter)="createUser()">
          <button mat-button *ngIf="lastName.value" matSuffix mat-icon-button aria-label="Clear" (click)="lastName.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="lastName.hasError('required')">
            Last Name is required.
          </mat-error>
          <mat-error *ngIf="lastName.hasError('maxlength')">
            Name must be less than 51 characters.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Email Address</mat-label>
          <input id="emailAddress" class="form-control" formControlName="emailAddress" matInput
                 (keyUp.enter)="createUser()">
          <button mat-button *ngIf="emailAddress.value" matSuffix mat-icon-button aria-label="Clear" (click)="emailAddress.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="emailAddress.hasError('required')">
            Email address is required.
          </mat-error>
          <mat-error *ngIf="emailAddress.hasError('email') && !emailAddress.hasError('required')">
            Must be a valid email address.
          </mat-error>
        </mat-form-field>
      </div>
      <div style="border-bottom: 1px solid #949494; padding: 7px 0;" *ngIf="!isEOR">
        <mat-checkbox formControlName="isClaimantUser" color="accent" style="padding:16px 0;">
          Set as Claimant User
        </mat-checkbox>
      </div>
      <div style="border-bottom: 1px solid #949494; padding: 7px 0;">
        <mat-checkbox formControlName="isAdmin" color="accent" style="padding:16px 0;">
          Create as an Admin user
        </mat-checkbox>
      </div>
      <div style="padding-top:17.5px;">
        <button mat-raised-button color="primary" class="main-button" (click)="createUser()">Create User</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<app-loading [hidden]="!loading"></app-loading>
