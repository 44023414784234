import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingService } from '../../services/branding.service';
import { UserService } from '../../services/UserService';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  public constructor(public brandingService: BrandingService) {
  }
}
