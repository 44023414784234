import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  saveFileAs(fileName: string, fileData: Blob, fileType: string) {
    saveAs(fileData, fileName, { type: fileType });
  }
}
