import {Component, ViewChild, Output, EventEmitter} from '@angular/core';
import { User } from '../../model/User';
import { EobApiService } from '../../services/EobApiService';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmRemoveDialogComponent } from './confirm-remove-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-user',
  templateUrl: 'select-user.component.html'
})
export class SelectUserComponent {
  users: User[];
  selectedUserId = -1;
  createUserSelected = false;

  @Output() userSelected: EventEmitter<User> = new EventEmitter();
  @Output() createUser: EventEmitter<boolean> = new EventEmitter();


  constructor(
    private userApiService: EobApiService,
    private apiErrorService: EobApiErrorService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.updateUsers([]);
  }

  updateUsers(users: User[]) {
    this.users = users;
  }

  selectUser(user: User) {
    this.createUserSelected = false;
    this.userSelected.emit(user);
    this.createUser.emit(false);
    this.selectedUserId = user.id;
  }

  isUserSelected(user: User): boolean {
    return(user.id === this.selectedUserId);
  }

  selectCreateUser() {
    this.createUserSelected = true;
    this.selectedUserId = -1;
    this.createUser.emit(true);
  }

  removeUser(selectedUser: User) {
    this.userApiService.removeUser(selectedUser).subscribe(() => {
      // Remove user from user search results
      let users = this.users.slice();

      users = users.filter(user => user.id !== selectedUser.id);

      this.updateUsers(users);

      // Deselect user
      this.userSelected.emit(undefined);
      this.selectedUserId = -1;
    },
    errorResponse => this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid Remove User'));
  }
}
