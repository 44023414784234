import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../model/User';
import { UserService } from '../../services/UserService';
import { EobApiService } from '../../services/EobApiService';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-manage-admin',
  templateUrl: './manage-admin.component.html',
})
export class ManageAdminComponent implements OnInit {
  @Input() SelectedUser: User;
  loading: boolean;

  get loadingOrUserNotSelected(): boolean {
    return this.loading || this.SelectedUser === undefined || this.SelectedUser === null;
  }

  constructor(private apiService: EobApiService,
    private apiErrorService: EobApiErrorService,
    private toastrService: ToastrService,
    private brandingService: BrandingService) {
  }

  ngOnInit() {
  }

  get isEOR() { return this.brandingService.isEor; }

  setClaimant(event: MatSlideToggleChange) {
    if (event.checked)
      this.makeCall(this.apiService.giveClaimantRights(this.SelectedUser.oktaUserId));
    else
      this.makeCall(this.apiService.removeClaimantRights(this.SelectedUser.oktaUserId));
  }

  setAdmin(event: MatSlideToggleChange) {
    if (event.checked)
      this.makeCall(this.apiService.giveAdminRights(this.SelectedUser.oktaUserId));
    else
      this.makeCall(this.apiService.removeAdminRights(this.SelectedUser.oktaUserId));
  }

  makeCall(call: Observable<object>) {
    call.subscribe(() => {
      this.toastrService.success('User rights updated.');
    },
      (error) => {
        this.apiErrorService.handleApiErrorResponse(error, 'Could not set claimant rights');
      },
      () => {
        this.loading = false;
      });
  }
}
