import { Component, OnInit } from '@angular/core';
import { EobApiService } from '../../services/EobApiService';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { OktaAuthService, UserClaims } from '@okta/okta-angular';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-manage-user-email',
  templateUrl: 'manage-user-email.component.html',
  styleUrls: ['manage-user-email.component.css']
})
export class ManageUserEmailComponent implements OnInit {
  receiveEmail: boolean;
  userClaims: UserClaims;
  itemDescription: string = !this.brandingService.isEor ? "ACH payments made for Explanations of Benefits" : "Stop Loss Transactions made for Explanations of Reimbursement"

  constructor(private apiService: EobApiService,
    private apiErrorService: EobApiErrorService,
    private oktaAuthService: OktaAuthService,
    private toastrService: ToastrService,
    private brandingService: BrandingService) {
  }

  async ngOnInit() {
    this.userClaims = await this.oktaAuthService.getUser();

    this.apiService.getCurrentUser().subscribe(
      user => this.receiveEmail = user.receiveEmail,
      error => this.apiErrorService.handleApiErrorResponse(error, 'Invalid User Request')
    );
  }

  async updateUser() {
    this.apiService.updateUserEmailPreference(this.userClaims.sub, this.receiveEmail).subscribe(
      _ => this.toastrService.success('Your email preferences have been updated.'),
      errorResponse => this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid User Email Preference'));
  }
}
