import { OnInit, Component, Inject } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { AuthenticationErrorService } from '../../services/AuthenticationErrorService';
import { WindowToken } from '../../window';
import { EobApiService } from '../../services/EobApiService';
import { ApplicationInsightsService } from '../../services/ApplicationInsightsService';
import { AppConfiguration } from '../../model/configuration';
import { UserService } from '../../services/UserService';
import { Router } from '@angular/router';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-eob-okta-callback',
  templateUrl: 'eob-okta-callback.component.html',
  styleUrls: ['eob-okta-callback.component.css']
})
export class EobOktaCallbackComponent implements OnInit {
  subdomain: string = '';
  error: string;
  constructor(private oktaAuthService: OktaAuthService,
    private configuration: AppConfiguration,
    private authenticationService: AuthenticationErrorService,
    private apiService: EobApiService,
    private applicationInsightsService: ApplicationInsightsService,
    private userService: UserService,
    private brandingService: BrandingService,
    private router: Router,
    @Inject(WindowToken) private window: Window) {
  }

  async ngOnInit() {
    try {
      this.applicationInsightsService.logVerbose('Okta Callback triggered.');

      await this.oktaAuthService.handleAuthentication();

      this.applicationInsightsService.logVerbose('Okta handleAuthentication completed.');

      await this.handleAuthenticationSuccess();

      this.applicationInsightsService.logVerbose(`${this.brandingService.title} handleAuthenticationSuccess successful.`);

    } catch (e) {
      this.handleAuthenticationError(e);
    }
  }

  async handleAuthenticationSuccess() {
    const token = await this.oktaAuthService.getAccessToken();

    this.apiService.accessToken = token;

    this.apiService.updateUserLastLogin().subscribe(
      async () => {
        if (await this.userService.isAdminUser()) {
          this.applicationInsightsService.logVerbose('EobOktaCallbackComponent detected user is admin and redirecting to users/manage.');

          await this.router.navigate(['user/manage']);

          return true;
        } else if (await this.userService.isAuthenticated()) {
          this.applicationInsightsService.logVerbose(`EobOktaCallbackComponent detected user is not admin and redirecting to ${this.brandingService.title}.`);

          await this.router.navigate([this.brandingService.subdomain]);
          return true;
        }
      },
      (error) => {
        this.applicationInsightsService.logError(error);

        this.router.navigate(['']);
      }
    );
  }

  handleAuthenticationError(error: any) {
    this.authenticationService.updateStatus(true);

    const userManagementSystemName = this.configuration.userManagementSystemName;

    console.log('Okta Error: ' + JSON.stringify(error));

    this.applicationInsightsService.logError(error);

    if (error.name === 'OAuthError') {
      if (error.message === 'User is not assigned to the client application.') {
        this.error = `Your ${userManagementSystemName} account is not registered with ${this.brandingService.title}. Please <a href="mailto:${this.brandingService.subdomain}@tribalfirst.com?subject=My InsureID account is not a member of ${this.brandingService.title} Users&body=Hello,%0D%0A%0D%0APlease grant me access to ${this.brandingService.title}">submit</a> a helpdesk ticket to complete this registration.`;

        return;
      }
    }

    this.error = `We are unable to log you into ${this.brandingService.title}. Please <a href="mailto:${this.brandingService.title}@tribalfirst.com?subject=I am unable to complete the InsureID login process to ${this.brandingService.title}">submit</a> a helpdesk ticket.`;
  }
}
