import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OktaAuthService } from '@okta/okta-angular';
import { ApplicationInsightsService } from './ApplicationInsightsService';

@Injectable({
    providedIn: 'root'
})
export class EobApiErrorService {

  constructor(private toastr: ToastrService,
    private oktaAuthService: OktaAuthService,
    private applicationInsightsService: ApplicationInsightsService) {
  }

  is4xxResponse(statusCode: number): boolean {
    return statusCode >= 400 && statusCode < 500;
  }

  is5xxOrGreaterResponse(statusCode: number): boolean {
    return statusCode >= 500;
  }

  responseMatchesEnvelope(errorResponse: any) {
    return errorResponse.error.errors;
  }

  handlePartialError(errorMessage: string) {
    this.toastr.error(errorMessage, 'System Error');
  }

  /// Inspects the error response provided by httpClient and provides relative messaging to the user
  /// via toastr based on requirements of response body schema and status code
  handleApiErrorResponse(errorResponse: any, contextualMessage: string) {
    const userMustLogInAgain = errorResponse.status === 401;
    const userDoesNotHavePermission = errorResponse.status === 403;

    if (userMustLogInAgain) {
      this.applicationInsightsService.logInfo('User attempted an operation but they received a 401 and have been logged out.');

      this.oktaAuthService.logout();

      return;
    }

    if (userDoesNotHavePermission) {
      this.applicationInsightsService.logWarning('User received a 403 response from the Api');

      this.toastr.warning(`You do not have permission to perform ${contextualMessage}`);

      return;
    }
    // When the errorResponse does not follow this schema then an exception has occured that was not wrapped in our expected envelope
    if (!this.responseMatchesEnvelope(errorResponse)) {
      this.applicationInsightsService.logError(errorResponse, null, null);

      this.toastr.error('A system error has occured. Please submit a helpdesk ticket.', 'System Error', { enableHtml: true });

      return;
    }

    let errorMessage = '';

    errorResponse.error.errors.forEach((error) => errorMessage += error.message + '<br /><br />');

    errorMessage = errorMessage.replace(new RegExp('<br /><br />$'), '');

    if (this.is4xxResponse(errorResponse.status)) {
      this.toastr.warning(errorMessage, contextualMessage, { enableHtml: true });

      return;
    }

    if (this.is5xxOrGreaterResponse(errorResponse.status)) {
      const formattedErrorMessage = errorMessage.endsWith('.') ? errorMessage : `${errorMessage}.`;

      this.toastr.error(`${formattedErrorMessage} Please try again or submit a ticket to our helpdesk.`,
        'System Error',
        { enableHtml: true });
    }
  }
}
