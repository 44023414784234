import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  title: string = '';
  helpEmail: string = '';
  helpdeskEmail: string = '';
  subdomain: string = '';
  cssClass: string = '';
  logoUrl: string = '';

  constructor() {
    if (window.location.hostname.indexOf('myeob') > -1)
      this.title = 'myEOB';
    else {
      if (window.location.hostname.indexOf('myeor') > -1)
        this.title = 'myEOR';
      else
        this.title = 'myEOB';
    }

    this.subdomain = this.title.toLowerCase();
    this.cssClass = `main-nav-${this.subdomain}`;
    this.logoUrl = `/assets/${this.subdomain}-header.png`;
    this.helpEmail = `mailto:${this.subdomain}@tribalfirst.com?subject=Help with Tribal First | ${this.title}`;
    this.helpdeskEmail = `mailto:${this.subdomain}@tribalfirst.com?subject=Help with Tribal First | ${this.title}`;

    if (isDevMode()) {
      console.log(`title = ${this.title}`);
      console.log(`subdomain = ${this.subdomain}`);
      console.log(`cssClass = ${this.cssClass}`);
      console.log(`logoUrl = ${this.logoUrl}`);
      console.log(`helpEmail = ${this.helpEmail}`);
      console.log(`helpdeskEmail = ${this.helpdeskEmail}`);
    }
  }

  public get isEor(): boolean {
    return this.title.toLowerCase() == 'myeor';
  }
}
