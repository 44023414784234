import { Injectable, OnInit } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ExceptionTelemetryEvent } from '../model/ExceptionTelemetryEvent';
import { TraceTelemetryEvent } from '../model/TraceTelemetryEvent';
import { AppConfiguration } from '../model/configuration';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private routerSubscription: Subscription;

  private appInsights: ApplicationInsights;

  constructor(private router: Router,
    private configuration: AppConfiguration) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.configuration.applicationInsightsInstrumentationKey
        }
      });

      this.appInsights.loadAppInsights();
      this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        }
      });
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logVerbose(message: string, properties?: { [key: string]: string }) {
    this.appInsights.trackTrace(new TraceTelemetryEvent(SeverityLevel.Verbose, message, properties));
  }

  logInfo(message: string, properties?: { [key: string]: string }) {
    this.appInsights.trackTrace(new TraceTelemetryEvent(SeverityLevel.Information, message, properties));
  }

  logWarning(message: string, properties?: { [key: string]: string }) {
    this.appInsights.trackTrace(new TraceTelemetryEvent(SeverityLevel.Warning, message, properties));
  }

  logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    this.appInsights.trackException(new ExceptionTelemetryEvent(error, properties));
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
