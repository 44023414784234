
import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { EobApiService } from '../../services/EobApiService';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Vendor } from '../../model/vendor';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../model/User';
import { VendorMappingChange } from '../../model/VendorMappingChange';
import { EobApiErrorService } from '../../services/EobApiErrorService';
import { VendorAssociation } from 'src/app/model/VendorAssociation';
import { throwError } from 'rxjs';
import { Exception } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-user-vendor-list',
  templateUrl: 'user-vendor-list.component.html',
})
export class UserVendorListComponent {
  displayedColumns: string[] = ['choose', 'vendorId', 'taxId', 'firmName'];
  dataSource: MatTableDataSource<VendorAssociation>;
  loading: boolean;
  filterValue: string;
  isSearchResults = false;
  get isClaimantUser(): boolean { return this.selectedUser.isClaimantUser }
  @Input() selectedUser: User;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() associationChanged: EventEmitter<VendorAssociation> = new EventEmitter();
  @Output() vendorSearchCleared: EventEmitter<boolean> = new EventEmitter();

  constructor(private eobApi: EobApiService,
    private apiErrorService: EobApiErrorService) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Data in data source will be in nested format { vendorAssociated: boolean, vendor }
    // In order to sort nested a custom accessor is required to return the appropriate property value
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'firmName':
          return item.vendor.firmName;
        case 'taxId':
          if (item.vendor.taxId == null)
            return -1
          else {
            const value = +item.vendor.taxId.replace(/[\D]+/, "")
            return value
          }
        case 'vendorId':
          if (item.vendor.vendorId == null)
            return -1
          else {
            const value = +item.vendor.vendorId.replace(/[\D]+/, "")
            return value
          }
        default:
          return item[property];
      }
    };

    // Data in data source will be in nested format { vendorAssociated: boolean, vendor }
    // In order to filter nested a custom filter predicate is required to compare values
    this.dataSource.filterPredicate = (vendorAssociation, filter: string) => !filter ||
      vendorAssociation.vendor.firmName.toLowerCase().includes(filter.toLowerCase()) ||
      vendorAssociation.vendor.taxId.toLowerCase().includes(filter.toLowerCase()) ||
      vendorAssociation.vendor.vendorId.toLowerCase().includes(filter.toLowerCase());
  }

  get hasNoUserSelected() {
    return this.selectedUser === undefined || this.selectedUser === null;
  }

  searchingVendors(searching: boolean) {
    this.loading = searching;
  }

  updateVendors(vendors: VendorAssociation[], isSearch: boolean) {
    this.dataSource.data = this.sortVendors(vendors);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isSearchResults = isSearch;
  }

  resetVendors() {
    this.vendorSearchCleared.emit(true);
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }

  selectVendor(event, vendorAssociation: VendorAssociation) {
    if (this.hasNoUserSelected) {
      return;
    }

    const vendorMapping = new VendorMappingChange();

    vendorMapping.userId = this.selectedUser.id;
    vendorMapping.vendorId = vendorAssociation.vendor.vendorId;
    this.loading = true;
    if (vendorAssociation.associatedToUser) {
      this.eobApi.removeVendorAssociation(vendorMapping).subscribe(() => {
        this.vendorAssociationCallback(vendorAssociation.vendor.vendorId, false);
      },
        errorResponse => {
          this.loading = false;
          this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid Disassociation');
        },
        () => {
          this.loading = false;
        });
    } else {
      this.eobApi.addVendorAssociation(vendorMapping).subscribe(() => {
        this.vendorAssociationCallback(vendorAssociation.vendor.vendorId, true);
      },
        errorResponse => {
          this.loading = false;
          this.apiErrorService.handleApiErrorResponse(errorResponse, 'Invalid Association');
        },
        () => {
          this.loading = false;
          this.associationChanged.emit(vendorAssociation);
        });
    }
  }

  sortVendors(vendors: VendorAssociation[]) {
    return vendors.sort((a, b) => {
      if (a.associatedToUser && !b.associatedToUser) {
        return -1;
      }
      if (!a.associatedToUser && b.associatedToUser) {
        return 1;
      }
      return 0;
    });
  }

  private vendorAssociationCallback(vendorId: string, associated: boolean) {
    const dataSourceVendor = this.dataSource.data.find((va) => va.vendor.vendorId === vendorId);

    dataSourceVendor.associatedToUser = associated;

    this.dataSource.data = this.sortVendors(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
