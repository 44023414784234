<app-search-users (resultsFound)="updateUsers($event)"></app-search-users>
<div class="userlist">
  <div *ngFor="let user of users" (click)="selectUser(user)" class="userlist-item clickable" [ngClass]="{'userlist-item-selected': isUserSelected(user)}" >
    <div class="userlist-name">{{user.firstName}} {{user.lastName}}</div>
    <div class="userlist-email">{{user.email}}</div> 
  </div>
  <div class="listitem-container userlist-item clickable" style="height: 64px; padding-left:10px" [ngClass]="{'userlist-item-selected': createUserSelected}" (click)="selectCreateUser()">
    <i class="material-icons">add</i>
    <div style="padding-left: 10px;">Add User</div>
  </div>
</div>
