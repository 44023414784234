import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/UserService';
import { ApplicationInsightsService } from '../services/ApplicationInsightsService';
import { BrandingService } from '../services/branding.service';

@Injectable({
  providedIn: 'root'
})
export class UserDefaultRouteGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private applicationInsightsService: ApplicationInsightsService,
    private brandingService: BrandingService) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.applicationInsightsService.logVerbose('UserDefaultRouteGuard considering activation.');

    if (await this.userService.isAdminUser()) {
      this.applicationInsightsService.logVerbose('UserDefaultRouteGuard detected user is admin and redirecting to users/manage.');

      await this.router.navigate(['user/manage']);

      return true;
    } else if (await this.userService.isAuthenticated()) {
      this.applicationInsightsService.logVerbose(`UserDefaultRouteGuard detected user is not admin and redirecting to ${this.brandingService.title}.`);

      await this.router.navigate([this.brandingService.subdomain]);

      return true;
    }

    this.applicationInsightsService.logVerbose('UserDefaultRouteGuard detected that user is not admin or authenticated and is not redirecting.');

    return true;
  }

}
