import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { User } from '../../model/User';

@Component({
  selector: 'confirm-remove-dialog',
  templateUrl: 'confirm-remove-dialog.component.html',
})
export class ConfirmRemoveDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmRemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
