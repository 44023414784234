import { Component } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { EobApiService } from '../services/EobApiService';
import { ToastrService } from 'ngx-toastr';
import { ApplicationInsightsService } from '../services/ApplicationInsightsService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  public tokenLoaded = false;

  constructor(
    private oktaAuth: OktaAuthService,
    private apiService: EobApiService,
    private toastr: ToastrService,
    private applicationInsightsService: ApplicationInsightsService) {
      this.oktaAuth.$authenticationState.subscribe(
        async (isAuthenticated: boolean) => {
          if (isAuthenticated) {
            const oktaUser = await this.oktaAuth.getUser();

            // oktaUser will be null when authentication was not successful
            if (oktaUser !== null && oktaUser !== undefined) {
              this.applicationInsightsService.setUserId(oktaUser.sub);
            }
          }
        },
        (error: any) => {
          this.toastr.error('There was an error logging you in. If this persists, please contact the helpdesk.', '');
        }
      );
  }

  async ngOnInit() {
    this.updateAccessToken();
  }

  public updateAccessToken() {
    this.oktaAuth.getAccessToken().then(token => {
      this.apiService.accessToken = token;
      this.tokenLoaded = true;
    });
  }
}
